import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo";
import imgNSPCC from '../../images/charity/nspcc.jpg'
import imgBarnados from '../../images/charity/barnados.jpg'
import imgSamaritans from '../../images/charity/samaritans.jpg'
import imgMind from '../../images/charity/mind.jpg'
import imgWomensAid from '../../images/charity/womensaid.jpg'
import imgHourGlass from '../../images/charity/hourglass.png'
import imgBreastCancer from '../../images/charity/breastcancer.jpg'


const charities = [
  {
    "url": "https://www.nspcc.org.uk",
    name: 'NSPCC',
    "img_name": imgNSPCC
  },
  {
    "url": "https://www.barnados.org.uk",
    name: 'Barnardos',
    "img_name": imgBarnados
  },
  {
    "url": "https://www.samaritans.org.uk",
    name: 'Samaritans',
    "img_name": imgSamaritans
  },
  {
    "url": "https://www.mind.org.uk",
    name: 'Mind',
    "img_name": imgMind
  },
  {
    "url": "https://www.womensaid.org.uk",
    name: 'Women\'s Aid',
    "img_name": imgWomensAid
  },
  {
    "url": "https://www.nationaldahelpline.org.uk/"
  },
  {
    "url": "https://wearehourglass.org/",
    name: 'HourGlass',
    "img_name": imgHourGlass
  },
  {
    "url": "https://www.againstbreastcancer.org.uk",
    name: 'Against Breast Cancer',
    "img_name": imgBreastCancer
  }
]

const Page = () => {

  return (
    <Layout pageTitle="Links">
      <SEO title="Links"/>
      <div className="info-right-panel">
        <ul className="links">
          {charities.map((_charity, idx) => (
          _charity.img_name ? <li key={idx} className="">
              <a target="_blank" title={`Go to the website of '${ _charity.name }'`} href={ _charity.url } rel="noreferrer">
                <img src={_charity.img_name} alt={`Logo for '${ _charity.name }'`} />
              </a>
            </li>
            : null))}
        </ul>
      </div>
      <div id="charity-links">
        <h2>Links</h2>
        <span>We encourage donations to the following charities:</span>
        <ul className="links">
          {charities.map((_charity, idx) => (
            <li key={idx}><a className="standard-link" target="_blank" href={_charity.url } rel="noreferrer">{_charity.url}</a></li>
          ))}
        </ul>
      </div>      
    </Layout>
  )
}

export default Page